import React from "react";
import FirstHalf from "../Components/FirstHalf";
import Timer from "../Components/Timer";

const MainScreen = () => {
  return (
    <div className="bg-[#fff] flex lg:flex-row md:flex-col flex-col justify-center items-center min-h-screen pb-10 lg:gap-28 md:gap-0 gap-0">
      <FirstHalf />
      <Timer />
    </div>
  );
};

export default MainScreen;
