import React, { useState, useEffect } from "react";

const Timer = () => {
  const calculateTimeLeft = () => {
    const targetDate = new Date("2024-07-26T00:00:00");
    const now = new Date();
    const difference = targetDate - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const renderTimeUnit = (value, label) => (
    <div className="flex flex-col md:flex-col lg:flex-col items-center mt-10">
      <div className="relative w-16 h-16 flex justify-center items-center transform rotate-45 bg-transparent text-black text-xl font-bold border border-black">
        <span className="transform -rotate-45">{value}</span>
      </div>
      <span className="mt-5 text-lg font-medium">{label}</span>
    </div>
  );

  return (
    <div className="flex flex-col md:flex-col lg:flex-row justify-center items-center lg:space-x-10 md:space-x-0 space-x-0 ">
      {timeLeft.days !== undefined ? (
        <>
          {renderTimeUnit(timeLeft.days, "Days")}
          {renderTimeUnit(timeLeft.hours, "Hours")}
          {renderTimeUnit(timeLeft.minutes, "Minutes")}
          {renderTimeUnit(timeLeft.seconds, "Seconds")}
        </>
      ) : (
        <span>Time's up!</span>
      )}
    </div>
  );
};

export default Timer;
