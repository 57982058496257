import React from "react";
import Image from "../Assets/Logo.png";

const FirstHalf = () => {
  return (
    <div className="">
      <div className="flex flex-col p-5 md:p-5 lg:p-0">
        <div className="">
          <img src={Image} alt="logo" style={{ width: "200px" }} />
        </div>

        <div className="mt-10">
          <h1 className="text-3xl ">OUR WEBSITE IS</h1>
          <p className="text-6xl font-bold">COMING SOON</p>

          <p className="mt-10 text-xl lg:text-2xl md:text-2xl font-light">
            Meanwhile feel free to intract with us
          </p>

          <a
            href="mailto:info@senvira.com"
            className="text-lg font-light text-blue-500 underline mt-5"
          >
            info@senvira.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default FirstHalf;
